<template>
    <div class="container">
        <h2 class="pt-2">Editar Hotspot</h2>
        <h4 class="c-color">Manzana: <strong>{{tarea.manzana}}</strong></h4>
        <h4 class="c-color"> Lote: <strong>{{tarea.lote}}</strong></h4>
        <form @submit.prevent="updateTarea({'tarea': tarea, 'index': index})">
            <Input :tarea="tarea" />
        </form>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Input from '../components/Input'
export default {
    components: {
        Input
    },
    computed: {
        ...mapState(['tarea', 'index'])
    },
    methods: {
        ...mapActions(['setTarea', 'updateTarea', 'setCurrentRoute'])
    },
    created() {
        this.setTarea(this.$route.params.id);
        this.setCurrentRoute('edit');

    }
}
</script>
