<template>
  <div class="container">

    <h2 class="mt-2 mb-4">Configuración</h2>
    <div id="passwordHelpBlock" class="form-text mb-2">
      Aquí puedes actualizar el número de contacto y el mensaje personalizado para que se contacten los clientes por
      medio
      de Whatsapp.
      Para mostrar en el mensaje que recibirás el número de lote y/o la manzana puedes agregarlos con
      <code>{&NoBreak;{lote}&NoBreak;}</code> y <code>{&NoBreak;{manzana}&NoBreak;}</code> respectivamente.
    </div>
    <i>Por Ejemplo: </i>
    <div id="passwordHelpBlock" class="form-text mb-2">
      Hola, me gustaría recibir información sobre el lote
      <code>{&NoBreak;{manzana}&NoBreak;}</code> - <code>{&NoBreak;{lote}&NoBreak;}</code>.
    </div>

    <form @submit.prevent="updateContactInfo(contactInfoTemp)">
      <div class="col">
        <div class="form-floating my-2">
          <input type="text" class="form-control mb-2" id="formPhoneNumber" placeholder="949392384"
            v-model.trim="contactInfoTemp.phoneNumber">
          <label for="formPhoneNumber" class="form-label">Número Celular (<i>9 dígitos</i>)</label>
        </div>

        <div class="my-2">
          <div class="form-floating">
            <textarea class="form-control" placeholder="" id="formMessage" style="height: 140px" maxlength="300"
              v-model="contactInfoTemp.message">
          </textarea>
            <label for="formMessage" class="">Mensaje</label>

          </div>
        </div>

      </div>

      <div class="d-grid gap-2">

        <button class="btn btn-primary btn-block mt-5" type="submit" :disabled="bloquear">
          Guardar
        </button>
      </div>

      <div class="d-grid gap-2">
        <router-link class="btn btn-secondary mt-4 ml-2 btn-bloc" :to="{
        name: 'Home'}">
          Volver
        </router-link>
      </div>




    </form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  data: () => {
    return {


    }
  },
  components: {
  },
  computed: {
    ...mapState(['contactInfoTemp', 'contactInfo']),
    bloquear() {
      if (this.contactInfoTemp.phoneNumber.length != 9) {
        return true;
      }
      if (this.contactInfoTemp.phoneNumber.trim() === "") {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions(['updateContactInfo', 'setContactInfo', 'setCurrentRoute']),

  },
  created() {
    this.setContactInfo(this.contactInfo);
    this.setCurrentRoute('settings');

  }
}
</script>
