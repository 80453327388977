<template>
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="d-none d-md-block col-md-5 ">
                <img class="img-fluid " src="../assets/login-portada.png" alt="" srcset="">
            </div>
            <div class="col-md-7 col-lg-7 px-md-5 align-self-center">

                <h1 class="mt-5 mb-4 c-font-style">Acceder a WEB 360°</h1>
                <div class="alert alert-danger" v-if="error.tipo !== null">
                    {{error.mensaje}}
                </div>
                <div class="alert alert-danger" v-if="localError !== ''">
                    {{localError}}
                </div>
                <div class="alert alert-primary" v-if="notification !== ''">
                    {{notification}}
                </div>
                <form @submit.prevent="procesarFormulario">
                    <label for="email">Tu correo electronico</label>
                    <input type="email" placeholder="Nombre@email.com" class="form-control mt-2 btn-square"
                        v-model.trim="email" :class="[error.tipo === 'email' ? 'is-invalid' : '']">

                    <div class="mt-3">
                        <label for="email">Clave</label>
                        <input type="password" placeholder="Ingresa tu contraseña" class="form-control mt-2 btn-square"
                            v-model.trim="pass1" :class="[error.tipo === 'password' ? 'is-invalid' : '']">
                    </div>


                    <div class="d-grid gap2 mt-4">
                        <button type="submit" class="btn btn-dark btn-square" :disabled="bloquear">
                            Ingresar
                        </button>
                    </div>



                    <div class="g-grid gap-2 col-12 max-auto d-md-flex justify-content-md-between mt-4">
                        <div class="form-check ">
                            <input class="form-check-input" type="checkbox" v-model="remember_email"
                                id="checkbox-email">
                            <label class="form-check-label" for="checkbox-email">
                                Acuérdate de mí
                            </label>
                        </div>

                        <p>
                            <a class="link-primary" @click.prevent="olvideLaContrasena" href="">¿Se te
                                olvidó tu contraseña?</a>
                        </p>


                    </div>

                </form>

            </div>

        </div>
    </div>

</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
    data() {
        return {
            email: "",
            pass1: "",
            remember_email: false,
            localError: "",
            notification: "",
        }
    },
    computed: {
        bloquear() {
            this.localError = '';
            this.notification = '';
            if (!this.email.includes('@')) {
                return true
            }
            if (this.pass1.length > 5) {
                return false
            }
            return true
        },
        ...mapState(['error', 'emailRemembered', 'currentRoute']),
        showNavbar: function () {
            return this.currentRoute != 'ingreso'
        }

    },
    methods: {
        ...mapActions(['ingresoUsuario', 'loadUserPreferences', 'setCurrentRoute', 'passwordReset']),
        async olvideLaContrasena() {
            if (!this.email.includes('@')) {
                this.localError = 'Complete el campo correo electrónico.';
                this.notification = '';
                return;
            }
            const result = confirm(`Enviar email para restablecer contraseña a ${this.email}`);
            if (!result) return;

            await this.passwordReset({ 'email': this.email })
            this.notification = 'Si el email existe, revise su bandeja de entrada o bandeja de correo no deseado.';


        },
        async procesarFormulario() {
            await this.ingresoUsuario({
                usuario: {
                    email: this.email, password: this.pass1
                },
                rememberEmail: this.remember_email
            });
            if (this.error.tipo != null) {
                return
            }
            this.email = ''
            this.pass1 = ''
        },
        loadEmail() {
            if (this.emailRemembered == "") return;

            this.email = this.emailRemembered;
            this.remember_email = true;
        }
    },
    created() {
        this.loadUserPreferences();
        this.loadEmail();
        this.setCurrentRoute('ingreso')
    }

}
</script>

<style>
.link {
    text-decoration: none;
    color: white;
}

.dark-theme {
    background-color: black;
}

.c-font-style {
    font-family: 'Roboto', sans-serif;
}

form>* {
    font-family: 'Inter', sans-serif;

}
</style>